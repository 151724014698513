<template>
    <div class="popup_container global_container_center">
        <div class="popup_main">
            <div class="popup_title global_container_center" :style="getMainColor()" v-if="config.title">{{config.title}}</div>
            <div class="popup_content global_container_center">
                <slot></slot>
            </div>
            <div class="popup_button_group global_container_center" v-if="config.button.cancel || config.button.sure">
                <div class="popup_button_cancel global_container_center" @click="doClose" v-if="config.button.cancel">
                    <span>{{config.button.cancel}}</span>    
                </div>
                <div class="popup_button_sure global_container_center" :style="getMainColor()" @click="doSure"  v-if="config.button.sure">
                    <span>{{config.button.sure}}</span>    
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name:"popup",
    props:{
        mainColor: {
        type: Object,
        default: () => {
            return {fontColor:"#9b0047",bgColor:"#f7ecef"};
        },
        },
        cfg:{
            type:Object,
            default:()=>{
                return {}
            }
        }
    },
    data(){
        return {
            
            config:{
                title:"添加常用语",
                button:{
                    cancel:"取消",
                    sure:"确认"
                }
            }
        }
    },
    created(){
        this.config = {
            ...this.config,...this.cfg
        }
    },
    methods:{
        doClose(){
            this.$emit("doClose")
        },
        doSure(){
            this.$emit("doSure")
        },
        getMainColor()
        {
            return `color:${this.mainColor.fontColor}`;
        }
    }
}
</script>
<style >
    .popup_container{
        position: fixed;
        left:0;
        top:0;
        width:100%;
        height:100%;
        background:rgba(30,30,30,0.2);
        z-index:99999;
    }
    .popup_main{
        background:#fff;
        border-radius:1vw;
        min-width:60%;
        max-width:80%;
    }
    .popup_title{
        height:8vw;
        font-size:3.6vw;
    }
    .popup_content{
        height:auto;
        min-height:20vw;
        display: flex;
        align-items: center;
        width:100%;
    }
    .popup_button_group{
        height:8vw;
        display: flex;
        border-top:1px solid #e6e6e6;
        font-size:3.6vw;
    }
    .popup_button_group div{
        height:100%;
        width:100%;
        flex:1 0 0;
    }
    .popup_button_cancel{
        border-right:1px solid #e6e6e6;
    }
</style>